/* eslint-disable import/no-default-export */
import { useEffect } from "react";

import { useRouter } from "next/router";

import { Button } from "@/components/FormElements/Button";
import { Layout } from "@/components/Layout";
import { ButtonVariants } from "@/types/inputFields";
import { track } from "@/helpers/analytics";

export default function Custom404() {
	const router = useRouter();

	useEffect(() => {
		if (router) {
			track("404", {
				url: router.asPath,
			});
		}
	}, [router]);

	return (
		<Layout hasFooter>
			<section className="container text-center sectionSpacing">
				<h1>Page not found</h1>
				<p className="center">
					Looks like you've been barking up the wrong tree!
				</p>
				<Button url="/" variant={ButtonVariants.default} width="relative">
					Back to home
				</Button>
			</section>
		</Layout>
	);
}
